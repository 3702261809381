$(function(){
  $('.open-ci').on({
    'mouseenter': function() {
      var dart_target = $(this).find(".fas");
      var open_target_id = $(this).attr("id") + "-target";
      var open_target = $("#" + open_target_id);
      // dart_target.removeClass("hidden");
      // open_target.removeClass("hidden");
      $(this).find(".color-target").addClass("colored")
      dart_target.fadeIn(70);
      open_target.fadeIn(70);
    },
    'mouseleave': function() {
      var dart_target = $(this).find(".fas");
      var open_target_id = $(this).attr("id") + "-target";
      var open_target = $("#" + open_target_id);
      $(this).find(".color-target").removeClass("colored")
      dart_target.fadeOut(50);
      open_target.fadeOut(50);
    }
  })

  $(document).on('click', '.open-ci-sp', function(){
    var open_target_id = $(this).attr("id") + "-target-sp";
    var open_target = $("#" + open_target_id);
    var move_bar = $(this).find(".vertical-b");
    open_target.fadeToggle(100);
    move_bar.toggleClass("open");
  });
});